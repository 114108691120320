<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <h1 class="mb-0 text-center">
            <span class="cursor-pointer" @click="year--"><</span>
            {{year}}
            <span class="cursor-pointer" @click="year++">></span>
          </h1>
        </b-card>
      </b-col>
      <b-col md="3" v-for="month in months" :key="month.date">
        <b-card @click="openAccounting(month.date)" :class="{ 'active-month': month.active }">
          <h3 class="text-center pb-5 pt-5">{{ month.name }}</h3>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      months: [],
      year: new Date().getFullYear(),
    }
  },
  mounted() {
    this.listMonths()
  },
  watch: {
    year() {
      this.listMonths()
    },
  },
  methods: {
    openAccounting(date) {
      this.$router.push({ name: 'monolit.accounting.list', params: { date } })
    },
    listMonths() {
      axios({
        method: 'get',
        url: `accounting/list-year/${this.year}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.months = response.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs találat',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style scoped>
    .active-month{
        background: #365A97;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }
    h3{
        color:#B2BBCA;
    }
    .cursor-pointer{
        cursor: pointer;
    }
</style>
